import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Mathlib {

  public static averageGrowth(initialValue: number, finalValue: number, nbOfPeriods: number): number {
    nbOfPeriods = nbOfPeriods === 0 ? 1 : nbOfPeriods;

    if ((initialValue > 0) && (finalValue > 0)) {
      // Divide the present value by the past value, multiply to the 1 / N power and then subtract one
      return Math.pow(this.safeDiv(finalValue, initialValue, 0), 1.0 / nbOfPeriods) - 1;
    }
    if ((initialValue < 0) && (finalValue < 0)) {
      return -(Math.pow(this.safeDiv(finalValue, initialValue, 0), 1.0 / nbOfPeriods) - 1);
    }
    if ((initialValue < 0) && (finalValue > 0)) {
      /* eslint-disable-next-line max-len */
      return Math.pow(this.safeDiv(finalValue - initialValue + Math.abs(initialValue), Math.abs(initialValue), 0), 1.0 / nbOfPeriods) - 1;
    }
    if ((initialValue > 0) && (finalValue < 0)) {
      /* eslint-disable-next-line max-len */
      return -(Math.pow(this.safeDiv(initialValue - finalValue + Math.abs(initialValue), Math.abs(initialValue), 0), 1.0 / nbOfPeriods) - 1);
    }
    return 0;
  }

  public static safeDiv(numerator: number, denominator: number, defaultValue: number, epsilon: number = 4): number {
    return (this.almostEqual(denominator, 0, epsilon) ? defaultValue : numerator / denominator);
  }

  public static almostEqual(value1: number, value2: number, precision: number = 8): boolean {
    if (precision < 0) {
      precision = 8; // default
    }
    if (precision > 20) {
      precision = 20;
    }
    return Math.abs(value1 - value2) < Math.pow(10, precision * -1);
  }

  public static getChange(value1: number | null, value2: number | null): number | null {
    if (!value1 && !value2) {
      return 0;
    }
    if (!value1 && value2) {
      return null;
    }
    return Math.round(((Number(value2) - Number(value1)) / Math.abs(Number(value1))) * 1000) / 10;
  }

  public static LinearTransformation(x: number, a: number, b: number, c: number, d: number): number | null {
    if (Mathlib.almostEqual(x, a) || Mathlib.almostEqual(c, d)) {
      return c;
    }

    if (Mathlib.almostEqual(x, b)) {
      return d;
    }

    if ((a < b) && (x < a || x > b)) {
      //HandleError($"The variable x ({x}) to transform linearly shoud be contained in [{a}; {b}]");
      x = Mathlib.putInRange(x, a, b);
    }

    if ((a > b) && (x > a || x < b)) {
      //HandleError($"The variable x ({x}) to transform linearly shoud be contained in [{b}; {a}]");
      x = Mathlib.putInRange(x, a, b);
    }

    if (Mathlib.almostEqual(a, b)) {
      // Cannot be resolved, we take the arbitrary return value c...
      //HandleError($"The limits a and b must not have the same value [{a}; {b}]");
      return c;
    }
    return c + ((d - c) * (x - a) / (b - a));
  }

  public static putInRange(value: number, limit1: number, limit2: number): number {
    if (value > Math.max(limit1, limit2)) {
      return Math.max(limit1, limit2);
    }

    if (value < Math.min(limit1, limit2)) {
      return Math.min(limit1, limit2);
    }
    return value;
  }
}
